import React, { useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { firebaseDb, firestoreDb } from "../firebase/firebase";
import {
  onSnapshot,
  query,
  where,
  collection,
  count,
  orderBy,
  limit,
  getDoc,
  doc,
  getCountFromServer,
} from "firebase/firestore";
import {
  useProjectCounterState,
  useRatingsCounterState,
  userReviewsCounterState,
  useProjectsPreviewState,
  useReviewsPreviewState,
  useRatingsPreviewState,
  useAchievementsPreviewState,
  useUserState,
} from "./globalStates";

let isReviewsCounterUpdated = false;
let isRatingsCounterUpdated = false;

export default function FirebaseListeners() {
  const projectCounter = useProjectCounterState((state) => state);
  const ratingsCounter = useRatingsCounterState((state) => state);
  const reviewsCounter = userReviewsCounterState((state) => state);
  const projectsPreview = useProjectsPreviewState((state) => state);
  const reviewsPreview = useReviewsPreviewState((state) => state);
  const ratingsPreview = useRatingsPreviewState((state) => state);
  const achievementsPreview = useAchievementsPreviewState((state) => state);
  const user = useUserState((state) => state);

  // Listen for project counter in database path public/app/count/verified

  useEffect(() => {
    const counterRef = ref(firebaseDb, "public/app/count/verified");
    const unsubscribe = onValue(counterRef, (snapshot) => {
      if (snapshot.exists()) {
        projectCounter.setCount(snapshot.val());
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // Add listener counting objects in reviews collection where review equals true for reviews and false for ratings

  useEffect(() => {
    if (isRatingsCounterUpdated) return;
    isRatingsCounterUpdated = true;
    getRatingsCount();
  }, []);

  useEffect(() => {
    if (isReviewsCounterUpdated) return;
    isReviewsCounterUpdated = true;
    getReviewsCount();
  }, []);

  const getReviewsCount = async () => {
    const q = query(
      collection(firestoreDb, "reviews"),
      where("review", "==", true)
    );
    const querySnapshot = await getCountFromServer(q);
    reviewsCounter.setCount(querySnapshot.data().count);
  };

  const getRatingsCount = async () => {
    const q = query(
      collection(firestoreDb, "reviews"),
      where("review", "==", false)
    );
    const querySnapshot = await getCountFromServer(q);
    ratingsCounter.setCount(querySnapshot.data().count);
  };

  // Fetch the latest 3 projects from the projects collection

 useEffect(() => {
    const q = query(
      collection(firestoreDb, "listings"),
      orderBy("createdAt", "desc"),
      limit(projectsPreview.count)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      projectsPreview.setIsFetched(false);
      const documents: any = [];
      querySnapshot.forEach((doc) => {
        documents.push(doc.data());
      });
      projectsPreview.setDocuments(documents);
      projectsPreview.setIsFetched(true);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch the latest 1 review from the reviews collection
  useEffect(() => {
    const q = query(
      collection(firestoreDb, "reviews"),
      where("review", "==", true),
      orderBy("createdAt", "desc"),
      limit(reviewsPreview.count)
    );
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      reviewsPreview.setIsFetched(false);
      const documents: any = [];
      querySnapshot.forEach(async (returnDoc) => {
        // Fetch project info from listings collection
        const listingId = returnDoc.data().listing;
        const listingRef = doc(firestoreDb, "listings", listingId);
        const listingDoc = await getDoc(listingRef);
        const tempDoc = {
          ...returnDoc.data(),
          listingData: listingDoc.data(),
          logo: listingDoc.data()?.logo ? listingDoc.data()?.logo : '',
        };
        documents.push(tempDoc);
        if (documents.length === querySnapshot.size) {
          reviewsPreview.setIsFetched(true);
          reviewsPreview.setDocuments(documents);
        }
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch the latest 3 ratings from the reviews collection

  useEffect(() => {
    const q = query(
      collection(firestoreDb, "reviews"),
      where("review", "==", false),
      orderBy("createdAt", "desc"),
      limit(ratingsPreview.count)
    );
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      ratingsPreview.setIsFetched(false);
      const documents: any = [];
      querySnapshot.forEach(async (ratingDoc) => {
        // Fetch project info from listings collection
        const listingId = ratingDoc.data().listing;
        const listingRef = doc(firestoreDb, "listings", listingId);
        const listingDoc = await getDoc(listingRef);
        documents.push({
          ...ratingDoc.data(),
          listingData: listingDoc.data(),
          logo: listingDoc.data()?.logo ? listingDoc.data()?.logo : '',
        });
        if (documents.length === querySnapshot.size) {
          ratingsPreview.setIsFetched(true);
          ratingsPreview.setDocuments(documents);
        }
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch all achievements which has a url from the achievements collection, and randomize 6 of them

 useEffect(() => {
    const q = query(
      collection(firestoreDb, "achievements"),
      where("url", "!=", "")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const documents: any = [];
      querySnapshot.forEach((doc) => {
        documents.push(doc.data());
      });
      const randomDocuments = documents
        .sort(() => Math.random() - 0.5)
        .slice(0, 6);
      
        achievementsPreview.setIsFetched(true);
      achievementsPreview.setDocuments(randomDocuments);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch user with highest points from publicUserInfo collection

  useEffect(() => {
    const q = query(
      collection(firestoreDb, "publicUserInfo"),
      orderBy("points", "desc"),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        user.setUser(doc.data());
      });
    });
    return () => {
      unsubscribe();
    };
  }
  , []);

  // Fetch user with highest points last week from publicUserInfo collection

  useEffect(() => {
    const dateAWeekAgo = Date.now() - 604800000;
    const q = query(
      collection(firestoreDb, "publicUserInfo"),
      where("pointsUpdated", ">", dateAWeekAgo),
      orderBy("latestWeekPoints", "desc"),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        user.setUserWeekly(doc.data());
      });
    });
    return () => {
      unsubscribe();
    };
  }, []);

   // Fetch user with highest points last day from publicUserInfo collection

  useEffect(() => {
    const dateADayAgo = Date.now() - 86400000;
    const q = query(
      collection(firestoreDb, "publicUserInfo"),
      where("pointsUpdated", ">", dateADayAgo),
      orderBy("latestDayPoints", "desc"),
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        user.setUserDaily(doc.data());
      });
    });
    return () => {
      unsubscribe();
    };
  }
  , []);

  return <></>;
}
