import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import TextField from "@mui/material/TextField";
import ButtonBase from "@mui/material/ButtonBase";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress } from "@mui/material";
import { useSearchState } from "../../../../components/utils/globalStates";
import { algoliaId, algoliaKey } from "../../../../.config/config";
import { materialFormsStylesSearchTopMobile } from "../../../../components/theme/materialGlobals";
import "./StartSearch.css";

const searchClient = algoliasearch(algoliaId, algoliaKey);
const index = searchClient.initIndex("gath3r_listings");

export default function StartSearchInput() {
  const searchState = useSearchState();

  useEffect(() => {
    let tempArray: any[] = [];
    searchState.setIsLoading(true);
    index
      .search(searchState.search, {
        hitsPerPage: 12,
      }
      )
      .then((res) => {
        for (let i = 0; i < res.hits.length; i++) {
          const tempListing = res.hits[i];
          tempArray.push(tempListing);
        }

        console.log(tempArray);
        searchState.setResults(tempArray);
        searchState.setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        searchState.setIsLoading(false);
      });
  }, [searchState.search]);

  return (
    <div className="StartSearchInputMobile">
      <TextField
        value={searchState.search}
        onChange={(event) => {
          searchState.setSearch(event.target.value);
        }}
        placeholder="Search for a project"
        fullWidth
        sx={materialFormsStylesSearchTopMobile}
        size="small"
        InputProps={{
          startAdornment: (
            <React.Fragment>
              <SearchIcon
                color="inherit"
                sx={{ fontSize: "18px", marginLeft: "5px", opacity: 0.6 }}
              />
            </React.Fragment>
          ),
          endAdornment: (
            <React.Fragment>
              {searchState.search.length > 0 && (
                    <ButtonBase
                        onClick={() => {
                        searchState.setSearch("");
                        }}
                        sx={{ margin: 0, marginRight: "5px", color: "#A0A0A0", fontSize: "12px", padding: "0px" }}
                    >
                        <p>X</p>
                    </ButtonBase>
                    )}
            </React.Fragment>
          ),
        }}
      />
    </div>
  );
}
