import React, { useState, useEffect, useRef } from "react";
import StartSearchInput from "./StartSearchInput";
import { Skeleton, Avatar, Tooltip } from "@mui/material";
import { useSearchState, useDeviceState } from "../../../../components/utils/globalStates";
import { LazyLoadImg } from "../../../../components/ui/LazyLoadImg";
import ProjectVerifiedBadge from "../../../../components/ui/badges/ProjectVerifiedBadge";
import ProjectRuggedBadge from "../../../../components/ui/badges/ProjectRuggedBadge";
import ProjectItemRating from "./ProjectItemRating";
import {useListingDialogState} from "../../../../components/utils/globalStates";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../../components/firebase/firebase";
import "./StartSearch.css";

const loadingTemplate = (
  <div className="StartSearchResultsItemMobile">
    <div className="StartSearchResultsItemTopMobile">
      <Skeleton
        variant="rectangular"
        sx={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          height: "26px",
          marginLeft: "10px",
          width: "calc(100% - 70px)",
        }}
      />
    </div>
    <Skeleton
      variant="text"
      sx={{
        height: "16px",
        marginTop: "10px",
        width: "100%",
      }}
    />

    <Skeleton
      variant="text"
      sx={{
        height: "16px",
        width: "100%",
      }}
    />

    <Skeleton
      variant="text"
      sx={{
        height: "16px",
        width: "100%",
      }}
    />

    <Skeleton
      variant="text"
      sx={{
        height: "16px",
        width: "80%",
      }}
    />
    <div className="StartSearchResultsItemTopMobile">
      <Skeleton
        variant="text"
        sx={{
          height: "10px",
          width: "30%",
          marginRight: "10px",
        }}
      />
    </div>
  </div>
);
export default function StartSearch() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchLength, setSearchLength] = useState(6);
  const searchState = useSearchState();
    const listingDialog = useListingDialogState();
    const deviceState = useDeviceState();
    const listingRef = useRef("");

    useEffect(() => {
    listingRef.current = listingDialog.listingId;
  }, [listingDialog.listingId]);

  useEffect(() => {
    if (isExpanded) {
      setSearchLength(12);
    } else {
      setSearchLength(6);
    }
  }, [isExpanded]);

  const onListingClick = (id: string) => {
    listingDialog.setListingId(id);
    setTimeout(() => {
      if (id.toString() === listingRef.current.toString()) {
        logEvent(firebaseAnalytics, "listing_click", {
          listing_id: id,
        });
      }
    }, 5000);
  };

  return (
    <div className="StartSearchMobile">
      <div className="StartSearchOuterMobile">
        <div className="StartSearchTopMobile">
          <p className="StartSearchTitleMobile">
            Find everything Web3 related in one space
          </p>
          <p className="StartSearchTextMobile">
            Community contributed and quality controlled web3 content available
            to everyone
          </p>
          <StartSearchInput />
        </div>
        <div className="StartSearchResultsMobile">
          {searchState.isLoading === true ? (
            <div className="StartSearchResultsInnerMobile">
              {loadingTemplate}
              {loadingTemplate}
              {loadingTemplate}
              {loadingTemplate}
              {loadingTemplate}
              {loadingTemplate}
              {isExpanded && loadingTemplate}
              {isExpanded && loadingTemplate}
              {isExpanded && loadingTemplate}
              {isExpanded && loadingTemplate}
              {isExpanded && loadingTemplate}
              {isExpanded && loadingTemplate}
              <div className="StartSearchResultsExpandButtonMobile">
                <Skeleton
                  variant="text"
                  sx={{
                    height: "14px",
                    width: "20%",
                  }}
                />
              </div>
            </div>
          ) : searchState.results.length === 0 ? (
            <p className="StartSearchTextMobile">
              Nothing found for "{searchState.search}"
            </p>
          ) : (
            <div className="StartSearchResultsInnerMobile">
              {searchState.results.map((result, index) => {
                if (index < searchLength) {
                  return (
                    <div className="StartSearchResultsItemMobile" onClick={() => onListingClick(result.id)} style={{cursor: 'pointer'}} key={index}>
                      <div
                        className="StartSearchResultsItemTopMobile"
                        style={{ marginTop: "0px" }}
                      >
                        {result.logo !== null &&
                        result.logo !== undefined &&
                        result.logo !== "" ? (
                          <div className="StartSearchResultsItemImgMobile">
                            <LazyLoadImg
                              // @ts-ignore
                              imgUrl={result.logo}
                            />
                          </div>
                        ) : (
                          <Avatar
                            sx={{
                              bgcolor: "#F5E3FF",
                              color: "#39393c",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              fontSize: "12px",
                            }}
                            alt={result.name}
                          >
                            {result.name.substr(0, 2)}
                          </Avatar>
                        )}
                        <div className="StartSearchResultsItemTopTextOuterMobile">
                          <div className="StartSearchResultsItemTopTextInnerMobile">
                            <p className="StartSearchResultsItemTitleMobile">
                              {result.name}
                            </p>
                            {result.manualVerified === true &&
                              result.rugged !== true && (
                                <Tooltip title="Verified by GATH3R">
                                  <div className="StartSearchResultsItemBadgeMobile">
                                    <ProjectVerifiedBadge />
                                  </div>
                                </Tooltip>
                              )}
                            {result.rugged === true && (
                              <Tooltip title="Rugged">
                                <div className="StartSearchResultsItemBadgeRuggedMobile">
                                  <ProjectRuggedBadge />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="StartSearchResultsItemTopMobile"
                        style={{ marginTop: "10px" }}
                      >
                        <ProjectItemRating listItem={result} />
                      </div>
                      <p
                        className="StartSearchResultsItemTextMobile"
                        style={{
                          color: '#39393c',
                        }}
                      >
                        {result.description}
                      </p>
                      <p
                        className="StartSearchResultsItemCategoryMobile"
                        style={{
                          color: result.rugged === true ? "#A0A0A0" : "#34CFC8",
                        }}
                      >
                        {result.category}
                      </p>
                    </div>
                  );
                }
              })}
              <div
                className="StartSearchResultsExpandButtonMobile"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <p className="StartSearchTextMobile">
                  <span style={{ fontSize: "90%", fontWeight: "500" }}>
                    {isExpanded
                      ? searchState.results.length <= searchLength - 1
                        ? "All results loaded"
                        : "Show less"
                      : searchState.results.length <= searchLength - 1
                      ? "All results loaded"
                      : "Show more"}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
