import React, { useState, useEffect } from "react";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import copy from "copy-to-clipboard";
import Rss3List from "./news/Rss3List";
import RssDBList from "./news/RssDbList";
import MediumList from "./news/MediumList";
import NewsList from "./news/NewsList";
import SearchInput from "./SearchInput";
import { getDoc, getFirestore, doc } from "firebase/firestore";
import { firestoreDb, firebaseAnalytics } from "../firebase/firebase";
import { useListingDialogState } from "../../components/utils/globalStates";
import { removeTwitterPrefix, removeUrlPrefix } from "../utils/parsers";
import { logEvent } from "firebase/analytics";

import FacebookLogo from "../ui/socials/FacebookLogo";
import XLogo from "../ui/socials/Xlogo";
import InstagramLogo from "../ui/socials/InstagramLogo";
import MediumLogo from "../ui/socials/MediumLogo";
import TelegramLogo from "../ui/socials/TelegramLogo";
import SubstackLogo from "../ui/socials/SubstackLogo";
import styles from "./listings.module.css";

export default function ListingDialog() {
  const listingDialogData = useListingDialogState();
  const [listing, setListing] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("news");

  useEffect(() => {
    if (listingDialogData.listingId === "") return;
    setListing(null);
    setSelectedValue("news");
    const getListing = async () => {
      const docRef = doc(firestoreDb, "listings", listingDialogData.listingId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setListing(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    getListing();
  }, [listingDialogData.listingId]);

  const onShareClick = (id: string) => {
    logEvent(firebaseAnalytics, "listing_share", {
      listing_id: id,
    });
    copy(`https://app.gath3r.co/p/${id}`);
    setOpen(true);
  };

  // Add visited listing to local storage, max 10

  const addVisitedListing = () => {
    let visitedListings: any[] = [];
    if (localStorage.getItem("visitedListings") !== null) {
      visitedListings = JSON.parse(localStorage.getItem("visitedListings")!);
    }
    if (visitedListings.length > 9) {
      visitedListings.shift();
    }
    // Check if listing is already in visitedListings
    for (let i = 0; i < visitedListings.length; i++) {
      const tempVisitedListing = visitedListings[i];
      if (tempVisitedListing.id === listing.id) {
        visitedListings.splice(i, 1);
      }
    }
    visitedListings.unshift({
      name: listing.name,
      id: listing.id,
      category: listing.category,
      visited: Date.now(),
    });
    localStorage.setItem("visitedListings", JSON.stringify(visitedListings));
  };

  useEffect(() => {
    if (listing !== null) {
      addVisitedListing();
    }
  }, [listing]);

  return listing !== null ? (
    <div className={styles.listingDialog}>
      <div className={styles.searchInputOuter}>
        <SearchInput />
      </div>
      <div className={styles.listingDialogHeaderOuter}>
        <div className={styles.listingDialogHeader}>
        <div className={styles.listingDialogHeaderName}>
          {listing.logo !== "" &&
          listing.logo !== undefined &&
          listing.logo !== null ? (
            <Avatar
              alt={listing.name}
              src={listing.logo}
              sx={{
                width: "80px",
                height: "80px",
                fontSize: "14px",
                borderRadius: "8px",
                "@media (max-width: 1800px)": {
                  width: "70px",
                  height: "70px",
                  fontSize: "13px",
                },

                "@media (max-width: 1200px)": {
                  width: "60px",
                  height: "60px",
                  fontSize: "11px",
                },

                "@media (max-width: 900px)": {
                  width: "50px",
                  height: "50px",
                  fontSize: "10px",
                },
                "@media (max-width: 600px)": {
                  width: "40px",
                  height: "40px",
                  fontSize: "9px",
                },
              }}
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: "#F5E3FF",
                color: "#39393c",
                width: "80px",
                height: "80px",
                fontSize: "14px",
                borderRadius: "8px",
                "@media (max-width: 1800px)": {
                  width: "70px",
                  height: "70px",
                  fontSize: "13px",
                },

                "@media (max-width: 1200px)": {
                  width: "60px",
                  height: "60px",
                  fontSize: "11px",
                },

                "@media (max-width: 900px)": {
                  width: "50px",
                  height: "50px",
                  fontSize: "10px",
                },
                "@media (max-width: 600px)": {
                  width: "40px",
                  height: "40px",
                  fontSize: "9px",
                },
              }}
              alt={listing.name}
            >
              {listing.name.substr(0, 2)}
            </Avatar>
          )}
          <p className={styles.listingDialogHeaderTitle}>{listing.name}</p>
        </div>
        <div className={styles.listingDialogHeaderSocial}>
          <IconButton
            aria-label="filter"
            sx={{ margin: 0, marginLeft: "auto", color: "#898989" }}
            size="small"
            onClick={() => onShareClick(listing.id)}
          >
            <ShareIcon
              sx={{
                fontSize: "5vw",
                "@media (min-width: 450px)": { fontSize: "4vw" },
                "@media (min-width: 700px)": { fontSize: "3vw" },
                "@media (min-width: 900px)": { fontSize: "24px" },
              }}
            />
          </IconButton>
        </div>
      </div>
      {listing.website !== undefined &&
        listing.website !== null &&
        listing.website !== "" && (
          <a
            href={listing.website}
            className={styles.listingWebsiteLink}
            target="_blank"
            rel="noopener noreferrer"
          >{`${removeUrlPrefix(listing.website)
            .charAt(0)
            .toUpperCase()}${removeUrlPrefix(listing.website).slice(1)}`}</a>
        )}
      <div className={styles.listingDialogBody}>
        <p className={styles.listingDialogDesc}>{listing.description}</p>
        <div className={styles.listingDialogDescSocial}>
          {listing.xHandle !== "" &&
            listing.xHandle !== undefined &&
            listing.xHandle !== null && (
              <a
                href={`https://x.com/${removeTwitterPrefix(listing.xHandle)}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.listingDialogHeaderSocialLink}
              >
                <XLogo />
              </a>
            )}
          {listing.facebookUrl !== "" &&
            listing.facebookUrl !== undefined &&
            listing.facebookUrl !== null && (
              <a
                href={listing.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.listingDialogHeaderSocialLink}
              >
                <FacebookLogo />
              </a>
            )}
          {listing.instagramHandle !== "" &&
            listing.instagramHandle !== undefined &&
            listing.instagramHandle !== null && (
              <a
                href={`https://instagram.com/${listing.instagramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.listingDialogHeaderSocialLink}
              >
                <InstagramLogo />
              </a>
            )}
          {listing.telegramHandle !== "" &&
            listing.telegramHandle !== undefined &&
            listing.telegramHandle !== null && (
              <a
                href={`https://t.me/${listing.telegramHandle}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.listingDialogHeaderSocialLink}
              >
                <TelegramLogo />
              </a>
            )}
            {listing.substackHandle !== "" &&
            listing.substackHandle !== undefined &&
            listing.substackHandle !== null && (
              <a
                href={`https://${removeTwitterPrefix(listing.substackHandle)}.substack.com`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.listingDialogHeaderSocialLink}
              >
                <SubstackLogo />
              </a>
            )}
          {listing.mediumHandle !== "" &&
            listing.mediumHandle !== undefined &&
            listing.mediumHandle !== null && (
              <a
                href={`https://medium.com/@${removeTwitterPrefix(listing.mediumHandle)}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.listingDialogHeaderSocialLink}
              >
                <MediumLogo />
              </a>
            )}
        </div>
        {listing.tags !== null &&
          listing.tags !== undefined &&
          Array.isArray(listing.tags) &&
          listing.tags.map((keyword: string) => (
            keyword !== '' && <p key={keyword} className={styles.listingDialogTag}>
              {keyword}
            </p>
          ))}
      </div>
      </div>
      
      {/*<div className={styles.listingDialogCategories}>
        <p className={styles.listingDialogCategory}>
          {(listing.category !== "" &&
          listing.category !== undefined &&
          listing.category !== null
            ? `${listing.category} / `
            : "") +
            (listing.subCategory !== "" &&
            listing.subCategory !== undefined &&
            listing.subCategory !== null
              ? `${listing.subCategory} / `
              : "") +
            (listing.subCategoryExtra !== "" &&
            listing.subCategoryExtra !== undefined &&
            listing.subCategoryExtra !== null
              ? `${listing.subCategoryExtra} / `
              : "")}
        </p>

          </div>*/}
      <div className={styles.radioButtons}>
        <FormControlLabel
          value="news"
          control={
            <Radio
              checked={selectedValue === "news"}
              onChange={(e) => setSelectedValue(e.target.value)}
              inputProps={{ "aria-label": "news" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="All News"
          sx={{
            ...RadioLabelStyle,
            color: selectedValue === "news" ? "#39393c" : "#999999",
          }}
        />
        {listing.mediumHandle !== null &&
        listing.mediumHandle !== undefined &&
        listing.mediumHandle !== "" ? (
          <FormControlLabel
            value="medium"
            control={
              <Radio
                checked={selectedValue === "medium"}
                onChange={(e) => setSelectedValue(e.target.value)}
                value="medium"
                inputProps={{ "aria-label": "medium" }}
                sx={{
                  ...radioButtonStyle,
                }}
              />
            }
            label="Medium"
            sx={{
              ...RadioLabelStyle,
              color: selectedValue === "medium" ? "#39393c" : "#999999",
            }}
          />
        ) : null}
        <FormControlLabel
          value="rss3"
          control={
            <Radio
              checked={selectedValue === "rss3"}
              onChange={(e) => setSelectedValue(e.target.value)}
              inputProps={{ "aria-label": "rss3" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="RSS3"
          sx={{
            ...RadioLabelStyle,
            color: selectedValue === "rss3" ? "#39393c" : "#999999",
          }}
        />
        <FormControlLabel
          value="rssdb"
          control={
            <Radio
              checked={selectedValue === "rssdb"}
              onChange={(e) => setSelectedValue(e.target.value)}
              value="rssdb"
              inputProps={{ "aria-label": "rss" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="Other"
          sx={{
            ...RadioLabelStyle,
            color: selectedValue === "rssdb" ? "#39393c" : "#999999",
          }}
        />
      </div>
      {selectedValue === "news" && (
        <NewsList
          activeListingName={listing.name}
          mediumActive={
            listing.mediumHandle !== null &&
            listing.mediumHandle !== undefined &&
            listing.mediumHandle !== ""
          }
          activeListingId={listing.id}
          mediumName={listing.mediumHandle}
        />
      )}
      {selectedValue === "rss3" && <Rss3List activeListing={listing.name} />}
      {selectedValue === "rssdb" && <RssDBList activeListing={listing.id} />}
      {selectedValue === "medium" &&
        listing.mediumHandle !== null &&
        listing.mediumHandle !== undefined &&
        listing.mediumHandle !== "" && (
          <MediumList activeListing={listing.mediumHandle} />
        )}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        message="Link copied to clipboard"
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#fff2ac",
            color: "#39393c",
            fontSize: "14px",
            fontWeight: 600,
            border: "1px solid #39393c",
          },
        }}
      />
    </div>
  ) : (
    <div className={styles.loadingDialog}>
      <CircularProgress color="inherit" />
    </div>
  );
}

const radioButtonStyle = {
  color: "#898989",
  backgroundColor: "#fdf7ea",
  padding: 0,
  "&.Mui-checked": {
    color: "#39393c",
    borderColor: "#39393c",
    backgroundColor: "#fdf7ea",
  },
};

const RadioLabelStyle = {
  color: "#999999",
  margin: 0,
  "& .MuiFormControlLabel-label": {
    fontSize: "3.5vw",
    fontWeight: 500,
    marginLeft: "5px",
    "@media (min-width: 350px)": { fontSize: "3vw" },
    "@media (min-width: 550px)": { fontSize: "2.5vw" },
    "@media (min-width: 701px)": { fontSize: "16px" },
  },
};
