import Button from "@mui/material/Button";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Gath3rLogo from "../../components/ui/Gath3rLogo";
import MobileLogo from "../../components/ui/MobileLogo";
import StartStats from "./components/StartStats";
import StartSearch from "./search/StartSearch";
import "./Start.css";

export default function Start() {
  return (
    <div className="Start">
      <div className="StartOuter">
        <div className="StartTop">
          <div className="AppHeaderLogo">
            <Gath3rLogo />
          </div>
        </div>
        <StartSearch />
        <div className="StartInner">
          <div className="StartInnerInfo">
            <p className="StartInnerTitle">GATH3R the Web3 directory</p>
            <div className="StartInnerInfoDivider"></div>
            <p className="StartInnerText">
              Simplifying, authenticating, and co-creating the future with
              GATH3R - your go-to Web3 directory that helps you expand your
              networks and resources in the expansive world of Web3.
            </p>
            <a
              href="https://app.gath3r.co/auth/register"
              rel="noreferrer"
              target="_blank"
              className="StartInnerText"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                color: "#729994",
              }}
            >
              <b>SIGN UP FOR FREE</b> - no subscription fees, no one-off
              payments.
            </a>
            <div className="StartInnerInfoButtons">
              <Button
                variant="outlined"
                startIcon={<HowToRegOutlinedIcon />}
                sx={{
                  backgroundColor: "#FFD379",
                  width: "140px",
                  maxWidth: "40%",
                  borderColor: "#39393C",
                  borderWidth: "1px",
                  borderRadius: "7px",
                  color: "#39393c",
                  boxShadow: "0px 1.5px 1px #39393C",
                  fontSize: "8px",
                  fontFamily: "Poppins",
                  padding: "4px",
                  textTransform: "none",
                  lineHeight: "1",
                  "&:hover": {
                    backgroundColor: "#ffd379",
                    opacity: 0.9,
                  },
                  "@media (min-width: 600px)": {
                    fontSize: "9px",
                  },
                  "@media (min-width: 900px)": {
                    fontSize: "10px",
                  },
                  "@media (min-width: 1200px)": {
                    fontSize: "11px",
                  },
                }}
                color="inherit"
                size="small"
                onClick={() => {
                  window.open(
                    "https://app.gath3r.co/auth/register",
                    "_blank noreferrer"
                  );
                }}
              >
                Sign Up
              </Button>
              <Button
                variant="outlined"
                startIcon={<LoginOutlinedIcon />}
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: "140px",
                  maxWidth: "40%",
                  borderColor: "#39393C",
                  borderWidth: "1px",
                  borderRadius: "7px",
                  color: "#39393c",
                  boxShadow: "0px 1.5px 1px #39393C",
                  fontSize: "8px",
                  fontFamily: "Poppins",
                  padding: "4px",
                  textTransform: "none",
                  lineHeight: "1",
                  marginLeft: "6%",
                  "&:hover": {
                    backgroundColor: "#ffd379",
                    opacity: 0.9,
                  },
                  "@media (min-width: 600px)": {
                    fontSize: "9px",
                  },
                  "@media (min-width: 900px)": {
                    fontSize: "10px",
                  },
                  "@media (min-width: 1200px)": {
                    fontSize: "11px",
                  },
                }}
                color="inherit"
                size="small"
                onClick={() => {
                  window.open(
                    "https://app.gath3r.co/auth/login",
                    "_blank noreferrer"
                  );
                }}
              >
                Log In
              </Button>
            </div>
          </div>
          <div className="StartInnerLogo">
            <div className="StartInnerLogoOverlay">
              <MobileLogo />
            </div>
          </div>
        </div>
        <StartStats />
      </div>
    </div>
  );
}
