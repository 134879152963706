/*import StartLogo from "../components/ui/StartLogo";
import ReviewLogo from "../components/ui/ReviewLogo";
import FolderLogo from "../components/ui/FolderLogo";
import ListingCount from "../components/listings/ListingCount";
import ListingTable from "../components/listings/ListingTable";
import Socials from "../components/ui/Socials";
import NewsletterForm from "../components/ui/forms/NewsletterForm";
import { Button } from "@mui/material";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import styles from "./mobile.module.css";

export default function Mobile() {
  return (
    <div className={styles.main}>
      <div className={styles.start}>
        <div className={styles.startOuter}>
          <div className={styles.startBody}>
            <div className={styles.startBodyLogo}>
              <StartLogo />
            </div>
            <div className={styles.startBodyText}>
              <p className={styles.startBodyTextTitle}>
                Welcome to GATH3R, Your portal to Web3 wonders.
              </p>
              <p className={styles.startBodyTextText}>
                Simplifying, authenticating, and co-creating the future with
                GATH3R - your go-to Web3 directory that helps you expand your
                networks and resources in the expansive world of Web3.
              </p>
            </div>
          </div>
        </div>
         <div className="StartInnerInfoButtons">
              <Button
                variant="outlined"
                startIcon={<HowToRegOutlinedIcon />}
                sx={{
                  backgroundColor: "#FFD379",
                  width: "140px",
                  maxWidth: "40%",
                  borderColor: "#39393C",
                  borderWidth: "1px",
                  borderRadius: "7px",
                  color: "#39393c",
                  boxShadow: "0px 1.5px 1px #39393C",
                  fontSize: "8px",
                  fontFamily: "Poppins",
                  padding: "4px",
                  textTransform: "none",
                  lineHeight: "1",
                  marginBottom: "150px",
                  marginTop: "50px",
                  "&:hover": {
                    backgroundColor: "#ffd379",
                    opacity: 0.9,
                  },
                  "@media (min-width: 600px)": {
                    fontSize: "9px",
                  },
                  "@media (min-width: 900px)": {
                    fontSize: "10px",
                  },
                  "@media (min-width: 1200px)": {
                    fontSize: "11px",
                  },
                }}
                color="inherit"
                size="small"
                onClick={() => {
                  window.open(
                    "https://app.gath3r.co/auth/register",
                    "_blank noreferrer"
                  );
                }}
              >
                Sign Up
              </Button>
              <Button
                variant="outlined"
                startIcon={<LoginOutlinedIcon />}
                sx={{
                  backgroundColor: "#FFFFFF",
                  width: "140px",
                  maxWidth: "40%",
                  borderColor: "#39393C",
                  borderWidth: "1px",
                  borderRadius: "7px",
                  color: "#39393c",
                  boxShadow: "0px 1.5px 1px #39393C",
                  fontSize: "8px",
                  fontFamily: "Poppins",
                  padding: "4px",
                  textTransform: "none",
                  lineHeight: "1",
                  marginLeft: "20%",
                  marginBottom: "150px",
                  marginTop: "50px",
                  "&:hover": {
                    backgroundColor: "#ffd379",
                    opacity: 0.9,
                  },
                  "@media (min-width: 600px)": {
                    fontSize: "9px",
                  },
                  "@media (min-width: 900px)": {
                    fontSize: "10px",
                  },
                  "@media (min-width: 1200px)": {
                    fontSize: "11px",
                  },
                }}
                color="inherit"
                size="small"
                onClick={() => {
                  window.open(
                    "https://app.gath3r.co/auth/register",
                    "_blank noreferrer"
                  );
                }}
              >
                Log In
              </Button>
            </div>
      </div>
      <div className={styles.info}>
        <div className={styles.infoInner}>
          <div className={styles.infoBodyText}>
            <p className={styles.startBodyTextTitle}>
              Personalize your dashboard, list, find, follow, and rate web3
              projects.
            </p>
          </div>
          <div className={styles.infoLogo}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fmobiles.png?alt=media"
              alt="device Icon"
              className={styles.infoLogoInner}
            />
          </div>
        </div>
        <div className={styles.infoFooter}>
          <p className={styles.infoFooterText}>Over 100 categories</p>
          <p className={styles.infoFooterText}>
            3,000+ Web3 projects and growing
          </p>
          <p className={styles.infoFooterText}>Accessible across the globe</p>
        </div>
      </div>
      <div className={styles.more}>
        <div className={styles.moreBodyText}>
          <p className={styles.moreBodyTextTitle}>
            GATH3R is as easy as opening the Yellow Pages.
          </p>
          <p className={styles.moreBodyTextText}>
            <b>Getting Started:</b> by Contributing to the Directory: At GATH3R,
            you&apos;re not just a user; you&apos;re a collaborator.
          </p>
          <p className={styles.moreBodyTextText}>
            Your contributions enrich the GATH3R directory, providing valuable
            insights for fellow Web3 explorers. List the projects as you
            navigate the space and help rate and review them so that others can
            gain insights into the impact and authenticity you experienced.
          </p>
        </div>
        <div className={styles.moreBodyLogo}>
          <ReviewLogo />
          <p className={styles.moreBodyTextText}>
            <b>Earning Points:</b> Just as businesses may have special offers
            in the Yellow Pages, on GATH3R, your active participation is
            rewarded with Points.
          </p>
        </div>
      </div>
      <div className={styles.listings}>
        <div className={styles.listingsInner}>
          <ListingCount />
          <ListingTable />
        </div>
      </div>
      <div className={styles.login}>
        <p className={styles.moreBodyTextText}>Follow us on Socials</p>
        <div className={styles.socialsOuter}>
          <Socials />
        </div>
        <div className={styles.newsletterOuter}>
          <NewsletterForm />
        </div>
      </div>
      
    </div>
  );
}*/

import Start from "./mobile/start/Start";
import Listings from "./mobile/listings/Listings";
import Info from "./mobile/info/Info";
import Points from "./mobile/points/Points";
import Dashboard from "./mobile/dashboard/Dashboard";
import Footer from "./mobile/footer/Footer";

import "./StartViewMobile.css";

export default function Mobile() {
  return (
    <div className="MainMobile">
      <Start />
      <Info/>
      <Points/>
      <Listings/>
      <Dashboard/>
      <Footer/>
    </div>
  );
}
