import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { IconButton } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { LazyLoadImg } from "../../../components/ui/LazyLoadImg";
import { PfpViewer } from "../../../components/ui/PfpViewer";
import {
  useAchievementsPreviewState,
  useUserState,
} from "../../../components/utils/globalStates";

import "./PointsComp.css";

const userEnums = [
  {
    name: "GATH3R WEEKLY LEADER",
    index: 0,
  },
  {
    name: "GATH3R DAILY LEADER",
    index: 1,
  },
  {
    name: "GATH3R ALL TIME LEADER",
    index: 2,
  },
];

export default function AchievementsPreview() {
  const [userIndex, setUserIndex] = useState(0);
  const [activeUser, setActiveUser] = useState<any>(null);
  const achievementsPreview = useAchievementsPreviewState((state) => state);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const user = useUserState((state) => state);

  // Set the active user based on the userIndex
  useEffect(() => {
    setIsImageLoading(true);
    if (userIndex === 0) {
      if (user.userWeekly !== null) {
        setActiveUser(user.userWeekly);
        setIsImageLoading(false);
      }
    } else if (userIndex === 1) {
      if (user.userDaily !== null) {
        setActiveUser(user.userDaily);
        setIsImageLoading(false);
      }
    } else if (userIndex === 2) {
      if (user.user !== null) {
        setActiveUser(user.user);
        setIsImageLoading(false);
      }
    }
  }, [userIndex, user.user, user.userDaily, user.userWeekly]);

  return (
    <div className="AchievementsPreview">
      <div className="AchievementsPreviewInner">
        {achievementsPreview.isFetched ? (
          achievementsPreview.documents.map((doc, index) => (
            <div className="AchievementsPreviewItem" key={index}>
              <div className="AchievementsPreviewItemImg">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={doc.url}
                />
              </div>
              <div className="AchievementsPreviewItemInner">
                <p className="AchievementsPreviewItemTitle">{doc.name}</p>
                <p className="AchievementsPreviewItemText">{doc.subtitle}</p>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="AchievementsPreviewItem">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInner"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItem">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInner"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItem">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInner"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItem">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInner"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItem">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInner"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItem">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInner"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {activeUser ? (
        <div className="AchievementsLeaderItem">
          <div className="AchievementsLeaderItemTitleOuter">
            <div className="AchievementsLeaderItemTitleArrowOuter">
              <IconButton
                sx={{ width: "15px", height: "10px", padding: "0px" }}
                onClick={() => {
                  let index = userIndex - 1;
                  if (index < 0) {
                    index = userEnums.length - 1;
                  }
                  setUserIndex(index);
                }}
              >
                <ArrowBackIosNewOutlinedIcon sx={{ width: "13px" }} />
              </IconButton>
            </div>
            <p className="AchievementsLeaderItemTitle">
              {userEnums[userIndex].name}
            </p>
            <div className="AchievementsLeaderItemTitleArrowOuter">
              <IconButton
                sx={{ width: "15px", height: "10px", padding: "0px" }}
                onClick={() => {
                  let index = userIndex + 1;
                  if (index >= userEnums.length) {
                    index = 0;
                  }
                  setUserIndex(index);
                }}
              >
                <ArrowForwardIosOutlinedIcon sx={{ width: "13px" }} />
              </IconButton>
            </div>
          </div>
          <div className="AchievementsLeaderItemImg">
            {isImageLoading ? (
              <Skeleton
                variant="circular"
                sx={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
            ) : (
              <PfpViewer
                // @ts-ignore
                pfpString={
                  activeUser.pfpString !== null &&
                  activeUser.pfpString !== undefined &&
                  activeUser.pfpString !== ""
                    ? activeUser.pfpString
                    : ""
                }
                randomize={
                  activeUser.pfpString !== null &&
                  activeUser.pfpString !== undefined &&
                  activeUser.pfpString !== ""
                    ? false
                    : true
                }
              />
            )}
          </div>
          <p className="AchievementsLeaderItemName">{activeUser.displayName}</p>
          <p className="AchievementsLeaderItemPoints">
            {new Intl.NumberFormat().format(
              userIndex === 2
                ? activeUser.points
                : userIndex === 1
                ? activeUser.latestDayPoints
                : activeUser.latestWeekPoints
            )}{" "}
            points
          </p>
        </div>
      ) : (
        <div className="AchievementsLeaderItem">
          <p className="AchievementsLeaderItemTitle">GATH3R POINTS LEADER</p>
          <div className="AchievementsLeaderItemImg">
            <Skeleton
              variant="circular"
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "#F5E3FF",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            />
          </div>
          <Skeleton variant="text" sx={{ fontSize: "18px", width: "80%" }} />
          <Skeleton
            variant="text"
            sx={{ fontSize: "18px", width: "80%", marginTop: "5px" }}
          />
        </div>
      )}
    </div>
  );
}
