export const materialFormsStyles = {
  "& .MuiOutlinedInput-root": {
    color: "#39393c",
    borderColor: "#39393c",
    "& fieldset": {
      borderColor: "#39393c",
      color: "#39393c",
    },
    "&:hover fieldset": {
      borderColor: "#39393c",
      color: "#39393c",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#39393c",
      color: "#39393c",
    },
  },
  "& label.Mui-focused": {
    color: "#39393c",
  },
  "& label": {
    color: "#39393c",
  },
};

export const materialButtonStyles = {
  "& .MuiButton-root": {
    color: "#39393c",
    borderColor: "#39393c",
    "&:hover": {
      backgroundColor: "#39393c",
      color: "#fff",
    },
  },
};

export const materialFormsStylesNew = {
  "& .MuiOutlinedInput-root": {
    color: "#39393c",
    borderColor: "#39393c",
    borderWidth: "0.5px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    "& fieldset": {
      borderColor: "#39393c",
    borderWidth: "0.5px",
      color: "#39393c",
    },
    "&:hover fieldset": {
      borderColor: "#39393c",
    borderWidth: "0.5px",
      color: "#39393c",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#39393c",
    borderWidth: "0.5px",
      color: "#39393c",
    },
  },
  "& label.Mui-focused": {
    color: "#39393c",
  },
  "& label": {
    color: "#39393c",
  },
};

export const materialButtonStylesNew = {
  "& .MuiButton-root": {
    color: "#39393c",
    borderColor: "#39393c",
    "&:hover": {
      backgroundColor: "#39393c",
      color: "#fff",
    },
  },
};

export const materialFormsStylesSearch = {
  "& .MuiOutlinedInput-root": {
    color: "#39393c",
    borderColor: "#39393c",
    borderWidth: "1px",
    backgroundColor: "#ffffff",
    "& fieldset": {
      borderColor: "#39393c",
      borderWidth: "1px",
      color: "#39393c",
    },
    "&:hover fieldset": {
      borderColor: "#39393c",
      borderWidth: "1px",
      color: "#39393c",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#39393c",
      borderWidth: "1px",
      color: "#39393c",
    },
  },
  "& label.Mui-focused": {
    color: "#39393c",
  },
  "& label": {
    color: "#39393c",
  },
};

export const materialFormsStylesSearchTop = {
  "& .MuiOutlinedInput-root": {
    color: "#39393c",
    borderColor: "#A0A0A0",
    borderWidth: "1px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: "10px",
    "& input": {
      textAlign: "center",
      fontSize: "7px",
      marginRight: "15px",
      // Media Queries
      "@media (min-width: 600px)": {
        fontSize: "9px",
      },
      "@media (min-width: 900px)": {
        fontSize: "11px",
      },
      "@media (min-width: 1200px)": {
        fontSize: "13px",
      },
      "@media (min-width: 1500px)": {
        fontSize: "15px",
      },
      
    },
    "& fieldset": {
      borderColor: "#A0A0A0",
      borderWidth: "1px",
      color: "#39393c",
    textAlign: "center",
    },
    "&:hover fieldset": {
      borderColor: "#A0A0A0",
      borderWidth: "1px",
      color: "#39393c",
    textAlign: "center",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0A0A0",
      borderWidth: "1px",
      color: "#39393c",
    textAlign: "center",
    },
  },
  "& label.Mui-focused": {
    color: "#39393c",
  },
  "& label": {
    color: "#39393c",
  },
};

export const materialFormsStylesSearchTopMobile = {
  "& .MuiOutlinedInput-root": {
    color: "#39393c",
    borderColor: "#A0A0A0",
    borderWidth: "1px",
    backgroundColor: "#ffffff",
    textAlign: "center",
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: "10px",
    "& input": {
      textAlign: "center",
      fontSize: "11px",
      marginRight: "15px",
      // Media Queries
      "@media (min-width: 400px)": {
        fontSize: "12px",
      },
      "@media (min-width: 600px)": {
        fontSize: "13px",
      },
      "@media (min-width: 800px)": {
        fontSize: "14px",
      },
      
    },
    "& fieldset": {
      borderColor: "#A0A0A0",
      borderWidth: "1px",
      color: "#39393c",
    textAlign: "center",
    },
    "&:hover fieldset": {
      borderColor: "#A0A0A0",
      borderWidth: "1px",
      color: "#39393c",
    textAlign: "center",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#A0A0A0",
      borderWidth: "1px",
      color: "#39393c",
    textAlign: "center",
    },
  },
  "& label.Mui-focused": {
    color: "#39393c",
  },
  "& label": {
    color: "#39393c",
  },
};
/* 

MuiInput: {
        root: {
        fontSize: '14px',
        lineHeight: '1.42857143',
        fontWeight: '400',
        color: '#555',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '6px 12px',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        '&:focus': {
            borderColor: '#66afe9',
            outline: '0',
            boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)',
        },
        },
    },
    MuiFormLabel: {
        root: {
        fontSize: '14px',
        lineHeight: '1.42857143',
        fontWeight: '400',
        color: '#555',
        padding: '6px 12px',
        },
    },
    MuiFormHelperText: {
        root: {
        fontSize: '12px',
        lineHeight: '1.42857143',
        fontWeight: '400',
        color: '#555',
        padding: '6px 12px',
        },
    },
    MuiSelect: {
        root: {
        fontSize: '14px',
        lineHeight: '1.42857143',
        fontWeight: '400',
        color: '#555',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '6px 12px',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        '&:focus': {
            borderColor: '#66afe9',
            outline: '0',
            boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)',
        },
        },
    },
    MuiInputBase: {
        root: {
        fontSize: '14px',
        lineHeight: '1.42857143',
        fontWeight: '400',
        color: '#555',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '6px 12px',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        '&:focus': {
            borderColor: '#66afe9',
            outline: '0',
            boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6)',
        },
        },
    },

*/
